module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-164778175-1","head":true,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter Blog","short_name":"GatsbyJS","start_url":"/","background_color":"#f6f5f0","theme_color":"#948f47","display":"minimal-ui","icon":"content/assets/sun.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-pinterest-twitter-facebook/gatsby-browser.js'),
      options: {"plugins":[],"delayTimer":1000,"pinterest":{"enable":true,"tall":false,"round":true},"twitter":{"enable":true,"containerSelector":null,"handle":null,"showFollowButton":true,"showTimeline":true,"showFollowerCount":true,"timelineTweetCount":1,"width":null,"height":null,"noHeader":true,"noFooter":true,"noBorders":true,"noScrollbar":true,"transparent":true},"facebook":{"enable":true,"containerSelector":null,"profile":null,"width":null,"height":null,"tabs":"timeline, events, messages","hideCover":false,"showFacepile":true,"smallHeader":false,"adaptContainerWidth":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
